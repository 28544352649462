<template>
  <h1 class="text-2xl font-semibold text-gray-900">Statistieken - Chauffeurs</h1>
  <div class="card flex flex-col gap-2">
    <div class="flex flex-col md:flex-row justify-between gap-2">
      <UITabs :tabs="tabs" v-model="tab" />
    </div>
    <div class="flex flex-row gap-4">
      <UISelectStatsFilter
        v-model:type="filters.type"
        v-model:day="filters.day"
        v-model:month="filters.month"
        v-model:year="filters.year"
        v-model:user_id="filters.user_id"
        toon-chauffeurs
      />
      <!-- @TODO: Wekelijkse stats -->
      <!-- v-model:week="filters.week" -->
      <!-- toon-week -->
    </div>
    <KeepAlive>
      <component :is="Views[tab]" :filters="filters" />
    </KeepAlive>
  </div>
</template>

<script setup>
import { defineAsyncComponent, computed, ref } from 'vue'
import UITabs from '@/components/UI/Tabs.vue'
import UISelectStatsFilter from '@/components/UI/Select/StatsFilter.vue'

const tabs = ['Lonen', 'Toekenning']
const tab = ref(0)
const Views = computed(() => tabs.map(tab => defineAsyncComponent(() => import(`./${tab}.vue`))))

const today = new Date()
const filters = ref({
  type: 'MAANDELIJKS',
  day: null,
  // week: null,
  month: today.getMonth() + 1,
  year: today.getFullYear(),
  user_id: null,
})
</script>
