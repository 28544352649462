<template>
  <h1 class="text-2xl font-semibold text-gray-900">Statistieken - Ritten</h1>
  <div class="card flex flex-col gap-2">
    <div class="flex flex-col md:flex-row justify-between gap-2">
      <UITabs :tabs="tabs" v-model="tab" />
    </div>
    <div class="flex flex-row gap-4">
      <UISelectStatsFilter
        v-model:type="filters.type"
        v-model:day="filters.day"
        v-model:month="filters.month"
        v-model:year="filters.year"
      />
      <!-- <UISelectChauffeur v-if="tab === 0" label nullable class="max-w-xs" v-model="filters.user_id" /> -->
    </div>
    <p class="bg-blue-200 inline-flex self-start items-center gap-1 pr-4 px-2 py-1 rounded-sm">
      <i class="fas fa-info bg-blue-400 text-white w-5 h-5 rounded-full mr-2 text-xs"></i>
      <small v-if="tab >= 1">Op basis van <b>boekings datum</b></small>
      <small v-else>Op basis van <b>vertrek/retour datum</b></small>
      <small v-if="tab <= 1 || tab == 4">(Bevestigde ritten)</small>
      <small v-if="tab == 2">(Gennuleerde ritten)</small>
    </p>
    <KeepAlive :key="Object.values(filters).join('')">
      <component :is="Views[tab]" :key="tab" :filters="filters" />
    </KeepAlive>
  </div>
</template>

<script setup>
import { defineAsyncComponent, computed, ref } from 'vue'
import UITabs from '@/components/UI/Tabs.vue'
import UISelectStatsFilter from '@/components/UI/Select/StatsFilter.vue'
// import UISelectChauffeur from '@/components/UI/Select/Chauffeur.vue'

const tabs = ['Uitgevoerd', 'Boekingen', 'Annulaties', 'Boekingsdagen', 'Promoties', 'Domeinen', 'Bronnen']
const tab = ref(0)
const Views = computed(() => tabs.map(tab => defineAsyncComponent(() => import(`./${tab}.vue`))))

const today = new Date()
const filters = ref({
  type: 'MAANDELIJKS',
  day: null,
  month: today.getMonth() + 1,
  year: today.getFullYear(),
})
</script>
