<template>
  <h1 class="text-2xl font-semibold text-gray-900">Statistieken - Promoties</h1>
  <div class="card flex flex-col gap-2">
    <div class="flex flex-col md:flex-row justify-between gap-2" v-if="tabs.length > 0">
      <UITabs :tabs="tabs" v-model="tab" />
    </div>
    <div class="flex flex-row gap-4">
      <UISelectStatsFilter
        v-model:type="filters.type"
        v-model:day="filters.day"
        v-model:month="filters.month"
        v-model:year="filters.year"
      />
    </div>
    <KeepAlive :key="Object.values(filters).join('')">
      <component :is="Views[tab]" :filters="filters" />
    </KeepAlive>
  </div>
</template>

<script setup>
import { defineAsyncComponent, computed, ref } from 'vue'
import UITabs from '@/components/UI/Tabs.vue'
import UISelectStatsFilter from '@/components/UI/Select/StatsFilter.vue'

const tabs = ['Lijst']
const tab = ref(0)
const Views = computed(() => tabs.map(tab => defineAsyncComponent(() => import(`./${tab}.vue`))))

const filters = ref({
  type: 'JAARLIJKS',
  day: null,
  month: null,
  year: null,
})
</script>
