<template>
  <h1 class="text-2xl font-semibold text-gray-900">Statistieken - Chauffeurs</h1>
  <div class="card flex flex-col gap-2">
    <div class="flex flex-col md:flex-row justify-between gap-2">
      <UITabs :tabs="tabs" v-model="tab" />
    </div>
    <div class="flex flex-row gap-4">
      <UISelectStatsFilter
        v-model:type="filters.type"
        v-model:day="filters.day"
        v-model:month="filters.month"
        v-model:year="filters.year"
      />
      <UISelectWagen active label nullable use-query class="max-w-xs" v-model="filters.wagen_id" />
      <UISelectDataOptions nullable label="Type" options-table="options_kosten_types" v-model="filters.kosten_type" />
      <UISelectFixed all label="Soort" type="KOSTEN_SOORT" v-model="filters.kosten_soort" />
    </div>
    <KeepAlive :key="Object.values(filters).join('')">
      <component :is="Views[tab]" :filters="filters" />
    </KeepAlive>
  </div>
</template>

<script setup>
import { defineAsyncComponent, computed, ref } from 'vue'
import UITabs from '@/components/UI/Tabs.vue'
import UISelectStatsFilter from '@/components/UI/Select/StatsFilter.vue'
import UISelectFixed from '@/components/UI/Select/Fixed.vue'
import UISelectWagen from '@/components/UI/Select/Wagen.vue'
import UISelectDataOptions from '@/components/UI/Select/SelectDataOptions.vue'

const tabs = ['Alles', 'Per Type', 'Per Soort', 'Per Wagen']
const tab = ref(0)
const Views = computed(() => tabs.map(tab => defineAsyncComponent(() => import(`./${tab.split(' ').join('')}.vue`))))

const today = new Date()
const filters = ref({
  type: 'MAANDELIJKS',
  day: null,
  month: today.getMonth() + 1,
  year: today.getFullYear(),
  wagen_id: null,
  kosten_type: null,
  kosten_soort: null,
})
</script>
